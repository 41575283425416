import { OptionModelWithUniqueId } from '@/common/models/OptionModel';
import { PredicateFilter } from '@/common/models/predicate/PredicateFilter';

export class SiteIntegrationInstanceProperties {
  ScriptId?: string;
  Name?: string;

  WebhookJsonData?: string;
  OAuthJsonData?: string;
  SlackJsonData?: string;
  BrazeJsonData?: string;

  constructor(props?: Partial<SiteIntegrationInstanceProperties>) {
    props = props || {};
    Object.assign(this, props);
  }

  getWebhookData() {
    if (!this.WebhookJsonData) return new WebhookInfoModel();
    try {
      return new WebhookInfoModel(JSON.parse(this.WebhookJsonData));
    } catch {
      return new WebhookInfoModel();
    }
  }

  setWebhookData(model: WebhookInfoModel) {
    this.WebhookJsonData = JSON.stringify(model);
  }

  getOAuthData() {
    //todo set this to be 'code' when we want to enable code flow
    if (!this.OAuthJsonData)
      return new OAuthInfoModel({ ResponseType: 'token' });
    try {
      return new OAuthInfoModel(JSON.parse(this.OAuthJsonData));
    } catch {
      return new OAuthInfoModel({ ResponseType: 'token' });
    }
  }

  setOAuthData(model: OAuthInfoModel) {
    this.OAuthJsonData = JSON.stringify(model);
  }

  getBrazeData() {
    if (!this.BrazeJsonData) return new BrazeIntegrationData();
    try {
      return new BrazeIntegrationData(JSON.parse(this.BrazeJsonData));
    } catch {
      return new BrazeIntegrationData();
    }
  }

  setBrazeData(model: BrazeIntegrationData) {
    this.BrazeJsonData = JSON.stringify(model);
  }
}

export interface BrazeIntegrationFieldMapping {
  id: string;
  source: string;
  target: string;
}

export class BrazeIntegrationData {
  key: string = '';
  apiKey: string = '';
  apiHost: string = '';
  optins: string = '';
  mappings?: BrazeIntegrationFieldMapping[] = [];

  constructor(props?: Partial<BrazeIntegrationData>) {
    if (!props) props = {};
    Object.assign(this, props);
    if (!this.mappings) this.mappings = [];
  }
}

export class OAuthInfoModel {
  AuthorizeDomain?: string;
  TokenDomain?: string;
  UserInfoDomain?: string;
  Scope?: string;
  ClientDomain?: string;
  ClientID: string = '';
  ClientSecret: string = '';
  AuthProvider: AuthProviders = AuthProviders.Auth0;
  ResponseType: ResponseType = 'token';

  constructor(props?: Partial<OAuthInfoModel>) {
    if (!props) props = {};
    Object.assign(this, props);
  }
}

type ResponseType = 'token' | 'code';

export enum AuthProviders {
  Google = 'Google',
  Auth0 = 'Auth0',
  OAuth = 'OAuth',
  Facebook = 'Facebook'
}

export class WebhookInfoModel {
  url: string;
  bodyFields?: OptionModelWithUniqueId[];
  httpHeaders?: OptionModelWithUniqueId[];
  triggerPredicates: PredicateFilter<WebhookTriggerPredicateType>;

  constructor(props?: Partial<WebhookInfoModel>) {
    if (!props) props = {};
    Object.assign(this, props);
    if (!this.bodyFields) this.bodyFields = [];
    if (!this.httpHeaders) this.httpHeaders = [];
  }
}

export enum WebhookTriggerTypes {
  //prize
  PrizeAwarded = 'PrizeAwarded',

  //site
  SiteViewed = 'SiteViewed',
  SignUpSuccess = 'SignUpSuccess',
  WebhookSendSuccess = 'WebhookSuccess',
  WebhookSendError = 'WebhookError',

  //cards
  CompetitionEntrySuccess = 'CompetitionEntrySuccess',
  QuizStarted = 'QuizStarted',
  QuizAnswered = 'QuizAnswered',
  QuizFinished = 'QuizFinished',
  CardViewed = 'CardViewed',
  CardAction = 'CardAction',
  CardClicked = 'CardClicked',
  CardCoverClicked = 'CardCoverClicked',
  CardShared = 'CardShared',
  NumberGuessStarted = 'NumberGuessStarted',
  NumberGuessVoted = 'NumberGuessVoted',
  VideoViewed = 'VideoViewed',
  VideoPlayed = 'VideoPlayed',
  VideoFinished = 'VideoFinished',
  InstantWinStarted = 'InstantWinStarted',
  InstantWinFinished = 'InstantWinFinished',
  ChecklistStarted = 'ChecklistStarted',
  ChecklistFinished = 'ChecklistFinished',
  ScratchAndWinStarted = 'ScratchAndWinStarted',
  ScratchAndWinFinished = 'ScratchAndWinFinished',
  CanvasStarted = 'CanvasStarted',
  CanvasFinished = 'CanvasFinished',
  PredictiveAnswered = 'PredictiveAnswered',
  PickerStarted = 'PickerStarted',
  PickerLayoutCompleted = 'PickerLayoutCompleted',
  SwiperStarted = 'SwiperStarted',
  SwiperSwipe = 'SwiperOptionSwiped',
  SwiperFinished = 'SwiperFinished',
  LiveSurveyStarted = 'LiveSurveyStarted',
  LiveSurveyQuestionSubmitted = 'LiveSurveyQuestionSubmitted',
  LiveSurveyQuestionApproved = 'LiveSurveyQuestionApproved',
  LiveSurveyQuestionRejected = 'LiveSurveyQuestionRejected',

  //trivia
  TriviaGameplayStarted = 'TriviaGameplayStarted',
  TriviaGameplayAnswered = 'TriviaGameplayAnswered',
  TriviaGameplayFinished = 'TriviaGameplayFinished',
  TriviaGameplayActionBarClicked = 'TriviaGameplayActionBarClicked',

  //coupons
  CouponIssued = 'CouponIssued',
  CouponViewed = 'CouponViewed',
  CouponRedemptionSuccess = 'CouponRedemptionSuccess',
  CouponRedemptionError = 'CouponRedemptionError'
}

export enum WebhookFilterTypes {
  Filter = 'Filter',
  All = 'All'
}

export enum WebhookTriggerPredicateType {
  IsEventType = 'IsEventType',
  IsFilterId = 'IsFilterId',
  IsFilterQuestion = 'IsFilterQuestion'
}
