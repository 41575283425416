import { SiteSettingsSections } from '@/admin/components/entertain/site/settings/_shared';
import { AdminCache } from '@/admin/data/AdminCache';
import { Guid } from '@/common/models/Guid';

const base = '/portal';
const sitesBase = base + '/sites/[siteId]';
const workspacesBase = base + '/workspaces/[workspaceId]';
const reportingBase = workspacesBase + '/reporting';
const workspaceSettingsBase = workspacesBase + '/settings';
const accountSettingsBase = base + '/settings';
const superUserSettingsBase = base + '/super-user-settings-v2';

export const AdminRoutes = {
  base: base,
  site: {
    base: sitesBase,
    page: sitesBase + '/pages/[pageId]',
    cards: sitesBase + '/cards',
    workflows: {
      base: sitesBase + '/workflows',
      runs: sitesBase + '/workflows/runs'
    },
    analytics: sitesBase + '/analytics',
    dataFeeds: sitesBase + '/data-feeds',
    coupons: sitesBase + '/coupons',
    settings: {
      hub: sitesBase + `/settings?section=${SiteSettingsSections.Hub}`,
      styling: sitesBase + `/settings?section=${SiteSettingsSections.Styling}`,
      users: sitesBase + `/settings?section=${SiteSettingsSections.Users}`,
      domain: sitesBase + `/settings?section=${SiteSettingsSections.Domain}`,
      dataCapture:
        sitesBase + `/settings?section=${SiteSettingsSections.DataCapture}`,
      terms: sitesBase + `/settings?section=${SiteSettingsSections.Terms}`,
      gdpr: sitesBase + `/settings?section=${SiteSettingsSections.GDPR}`,
      integrations:
        sitesBase + `/settings?section=${SiteSettingsSections.Integrations}`,
      superUser:
        sitesBase + `/settings?section=${SiteSettingsSections.SuperUser}`,
      leaderboards:
        sitesBase + `/settings?section=${SiteSettingsSections.Leaderboards}`
    }
  },
  workspaces: {
    base: workspacesBase,
    hubs: workspacesBase + '/hubs',
    workflows: {
      base: workspacesBase + '/workflows',
      runs: workspacesBase + '/workflows/runs'
    },

    contacts: {
      base: workspacesBase + '/contacts?restore',
      contact: workspacesBase + '/contacts/[contactId]',
      properties: workspacesBase + '/contacts/properties',
      lists: {
        base: workspacesBase + '/contacts/lists',
        list: workspacesBase + '/contacts/lists/[listId]'
      },
      syncs: {
        base: workspacesBase + '/contacts/syncs',
        detail: (autoEdit?: boolean) =>
          workspacesBase + `/contacts/syncs/[syncId]${autoEdit ? '?edit' : ''}`
      },
      communicationSubscriptions: {
        base: workspacesBase + '/contacts/communication-subscriptions'
      }
    },
    reporting: {
      base: reportingBase,
      insights: reportingBase + '/insights',
      insight: reportingBase + '/insights/[insightId]',
      dashboards: reportingBase + '/dashboards',
      dashboard: reportingBase + '/dashboards/[dashboardId]'
    },
    settings: {
      base: workspaceSettingsBase,
      contactProperties: workspaceSettingsBase + '/contact-properties',
      dataExports: workspaceSettingsBase + '/data-exports',
      entryRules: workspaceSettingsBase + '/entry-rules',
      referenceTables: workspaceSettingsBase + '/reference-tables',
      emails: workspaceSettingsBase + '/emails'
    }
  },
  accountSettings: {
    base: accountSettingsBase,
    workspace: accountSettingsBase + '?section=workspaces'
  },
  superUserSettings: {
    base: superUserSettingsBase,
    companies: superUserSettingsBase + '/companies',
    plans: superUserSettingsBase + '/plans',
    other: {
      base: superUserSettingsBase + '/other',
      templates: superUserSettingsBase + '/other?section=template-creations',
      webhooks: superUserSettingsBase + '/other?section=webhooks',
      cache: superUserSettingsBase + '/other?section=cache'
    }
  }
};

export const resolveAdminRoute = (
  route: string,
  slugs: Record<string, Guid | string> = {},
  queryParams?: URLSearchParams
): string => {
  let result = route;
  if (!slugs['workspaceId']) {
    // by default replace the workspaceId slug if it is not provided
    result = route.replace('[workspaceId]', AdminCache.workspaceId?.toString());
  }

  for (const key in slugs) {
    result = result.replace(`[${key}]`, slugs[key].toString());
  }

  if (queryParams) {
    result +=
      result.indexOf('?') === -1
        ? `?${queryParams.toString()}`
        : `&${queryParams.toString()}`;
  }

  return result;
};
